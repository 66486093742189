import { h } from 'preact';
import { Switch, Route } from 'wouter-preact';
import GameBoard from './components/GameBoard/GameBoard';

var Root = function Root() {
  return h("div", null, h(Switch, null, h(Route, {
    path: "/:encodedGameBoard?"
  }, function (params) {
    return h(GameBoard, {
      encodedBoard: params.encodedGameBoard
    });
  })));
};

export default Root;