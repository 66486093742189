// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2jygqCXlcgDVmPMWqHxVy5 {\n    width: 50%;\n    margin: 0 auto;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    grid-template-rows: 100px 100px 100px 100px 100px;\n    font-family: Arial, sans-serif;\n    justify-items: center;\n    row-gap: 5px;\n}\n\n._3TU1m5YEnVOaVwKeW2E56D {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n\n    margin-bottom: 50px;\n}\n\n._3TU1m5YEnVOaVwKeW2E56D > :not(:last-child) {\n    margin-right: 2rem;\n}\n\n._1TFmNQgXG67aG--zZ6aS4f {\n    text-decoration: line-through;\n}\n\n._2sO_jO506VKSUuYCcngXs_ {\n    width: 80%;\n    height: 90%;\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #0000001c;\n\n    box-shadow: 0 5px 8px 1px #d3d0d0;\n\n    cursor: pointer;\n}\n\n._3QjE99aEhyqIQAWZJDo5TM {\n    background-color: greenyellow;\n    color: black;\n}\n\n._3Eqr61PM2H_53MawhSygIs {\n    background-color: blue;\n    color: white;\n}\n\n._2okL13OwgU8ls_xpefHq4k {\n    background-color: #e8d9ce;\n    color: black;\n}\n\n._1kbs0nkjFLLwMEnWOv8GIG {\n    background-color: black;\n    color: white;\n}\n\n._36fSSpwWT8Mnwdwk_UOMX0 {\n    background-color: black;\n    color: white;\n    font-size: 1rem;\n    border-radius: 3px;\n    border: none;\n    padding: 10px 23px;\n    cursor: pointer;\n    text-transform: uppercase;\n    box-shadow: 0 2px 8px 1px #d6d6d6;\n}\n\n", ""]);
// Exports
exports.locals = {
	"container": "_2jygqCXlcgDVmPMWqHxVy5",
	"playerSelect": "_3TU1m5YEnVOaVwKeW2E56D",
	"selected": "_1TFmNQgXG67aG--zZ6aS4f",
	"gamePiece": "_2sO_jO506VKSUuYCcngXs_",
	"greenCard": "_3QjE99aEhyqIQAWZJDo5TM",
	"blueCard": "_3Eqr61PM2H_53MawhSygIs",
	"normalCard": "_2okL13OwgU8ls_xpefHq4k",
	"assassinCard": "_1kbs0nkjFLLwMEnWOv8GIG",
	"button": "_36fSSpwWT8Mnwdwk_UOMX0"
};
module.exports = exports;
